import React, { useEffect } from "react"
import { Router } from "@reach/router"
import * as uuid from "uuid"
import * as qs from "query-string"
import { LoginScreen } from "@modules/login/cloud/components/LoginScreen"
import { useUserOrganizationsQuery } from "@modules/header/queries.generated"
import { navigate } from "gatsby"
import { AuthRedirect } from "./features/Auth"
import {
  SiteCreationTypePicker,
  ImportSiteWizard,
  DeployNowWizard,
} from "./features/CreateSite"
import { ProtectedRoute } from "./features/Auth"
import { CmsVendor } from "@modules/graphql/types"
import {
  StandardPageContentSection,
  StandardSingleColumn,
} from "@modules/ui/layouts/Containers"

import { Button, Spacer } from "gatsby-interface"
import { MdArrowForward } from "react-icons/md"

const authServiceUrl = process.env.GATSBY_DASHBOARD_AUTHENTICATION_URL

function Login({ location }) {
  const query = qs.parse(location.search)

  useEffect(() => {
    setShopifyDataInLocalStorage({
      shopifyToken: query.token,
      shopifyStoreFrontToken: query.storeFrontToken,
      shopName: query.shop,
    })
  }, [])

  const onRedirectClick = (queryString, loginOption = `GITHUB`) => {
    const parsed = qs.parse(queryString)
    if (!parsed.csrfToken && !parsed.invitationToken) {
      parsed.csrfToken = uuid.v4()
    }

    return window.location.assign(
      `${authServiceUrl}/oauth/${loginOption.toLowerCase()}?${qs.stringify(
        parsed
      )}`
    )
  }

  return (
    <LoginScreen
      location={location}
      actionType="login"
      onLoginOptionClick={loginOption => {
        onRedirectClick(
          `?loginRedirectUrl=${encodeURIComponent(
            `/site-create/shopify-create-site`
          )}&frameable=true&close=true`,
          loginOption
        )
      }}
    />
  )
}

function LoggedIn() {
  const {
    data: userOrganizationsData,
    loading: loadingUserOrganizations,
  } = useUserOrganizationsQuery()

  if (loadingUserOrganizations) {
    return null
  }

  return (
    <section>
      <h1>Create a new Shopify/Gatsby site</h1>
      <h2>Shopify Starter</h2>

      <p>
        Choose this option to create a new site from our official{" "}
        <a
          href="https://github.com/gatsbyjs/gatsby-starter-shopify"
          target="_blank"
          rel="noreferrer"
        >
          Gatsby Shopify Starter
        </a>
      </p>
      <Button
        size="L"
        leftIcon={<MdArrowForward />}
        onClick={() =>
          navigate(
            `/site-create/deploynow/?url=https://github.com/gatsbyjs/gatsby-starter-shopify`
          )
        }
      >
        Create a new site from Shopify starter
      </Button>

      <Spacer size={13} />

      <h2>Gatsby Cloud Organizations</h2>

      <p>
        Select a Gatsby Cloud organization to create a site from your existing
        Git repo.
      </p>
      {userOrganizationsData?.currentOrganizations?.map(organization => {
        return (
          <section key={organization.id}>
            <Button
              size="L"
              leftIcon={<MdArrowForward />}
              onClick={() =>
                navigate(
                  `/site-create/organization/${organization.id}/sites/create`
                )
              }
            >
              {organization.name}
            </Button>
            <Spacer size={5} />
          </section>
        )
      })}
    </section>
  )
}

function setShopifyDataInLocalStorage({
  shopifyToken,
  shopifyStoreFrontToken,
  shopName,
}) {
  window.localStorage.setItem(`gatsby:shopifyapptoken`, shopifyToken)
  window.localStorage.setItem(`gatsby:shopifyshop`, shopName)
  window.localStorage.setItem(
    `gatsby:shopifyappstorefronttoken`,
    shopifyStoreFrontToken
  )
}

function CreateSiteFlow({ location }) {
  const query = qs.parse(location.search)

  return (
    <StandardPageContentSection>
      <StandardSingleColumn>
        <LoggedIn
          token={query.token}
          apiKey={query.apiKey}
          shopName={query.shop}
        />
      </StandardSingleColumn>
    </StandardPageContentSection>
  )
}

function SiteCreate() {
  return (
    <Router>
      <AuthRedirect path="/site-create/redirect" />

      <Login path="/site-create/login" />

      <ProtectedRoute
        component={props => (
          <DeployNowWizard
            frameable={true}
            frameableCmsVendor={CmsVendor.Shopify}
            {...props}
          />
        )}
        path="/site-create/deploynow"
      />

      <ProtectedRoute
        component={props => (
          <SiteCreationTypePicker frameable={true} {...props} />
        )}
        path="/site-create/organization/:organizationId/sites/create"
      />

      <ProtectedRoute
        component={props => (
          <ImportSiteWizard
            frameable={true}
            rootPath="site-create"
            frameableCmsVendor={CmsVendor.Shopify}
            {...props}
          />
        )}
        path="/site-create/organization/:organizationId/sites/import/repository"
      />

      <ProtectedRoute
        component={props => (
          <ImportSiteWizard
            frameable={true}
            rootPath="site-create"
            frameableCmsVendor={CmsVendor.Shopify}
            {...props}
          />
        )}
        path="/site-create/organization/:organizationId/sites/import/sourceProvider"
      />

      <ProtectedRoute
        component={props => (
          <ImportSiteWizard
            frameable={true}
            rootPath="site-create"
            {...props}
          />
        )}
        path="/site-create/organization/:organizationId/sites/import/:siteId/setup"
      />

      <ProtectedRoute
        component={CreateSiteFlow}
        path="/site-create/shopify-create-site"
      />
    </Router>
  )
}
export default SiteCreate
